.ratingContainer {
  outline: none;
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  background-color:var(--primaryBackground);
  border-radius: 5px;
}
.ratingContentConainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.ratingSubHeading {
  color: #909090;
  text-align: center;
  padding: 0px;
  margin: 0px;
  font-size: 17px;
}
.ratingButtonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5%;
}
.ratingTitleText {
  font-weight: 900;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
}
.iconBox {
  padding: 10px;
}
.icon-height {
  height: 20px;
}
.hashTagText {
  color: #909090;
  font-size: 14px;
  font-weight: bolder;
  text-align: center;
  padding: 0px;
  margin: 0px;
}
.sharingText {
  padding: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
}
.buyForFriendButtContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;
}
.mainDiv {
  flex: 1;
  display: flex;
}
.thumbsTopContainer {
  padding: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thumbsIconContainer {
  padding: 0px 20px;
}
.thumbsIcon {
  height: 65px;
  cursor: pointer;

}
.ratingHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7%;
  position: relative;
}
.ratingFooterButton {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4%;
  position: relative;
}

@media (max-width: 650px) {
  .ratingContainer {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}
