.methodContainer {
  height: 40px;
  border-radius: 5px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 30px;
  cursor: pointer;
  background-color: var(--SelectedDarkBackground);
  color: var(--PrimaryText);
}
.rightArrow {
  color: #ffffff;
}
.paymentMethodTitle {
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: var(--PrimaryText);
}
.visaImagesContainer {
  display: flex;
  align-items: center;
  display: flex;
}
.paymentMethodContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 20px;
}

.methodContainer:hover {
  background-color: #282ddb;
}
.rightArrowIcon {
  flex: 1px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.visa-img{
  height: 20px;
   margin-top: 5px
}

.master-card-img{
  height: 20px
}
.paypal-img{
  height: 20px
}

.easypaisa-img{
  height: 15px
}

.jazzcash-img{
  height: 25px
}

.payment-img-container{
  display: flex; align-items: center
}

@media (max-width: 600px) {
  .paymentMethodTitle {
    flex: 1.2;
    font-size: 13px;
  }
}
