.continueButton{
    border:1px solid #ff0000;
    border-radius:5px;
    height:35px;
    width:300px;
    display:flex;
    justify-content:center;
    align-items:center;
    color:#000000;
    font-weight:600;
    font-size:15px;
    cursor:pointer;
}

.mainPurchasedContainer{
margin-top:60px
}
.mainPurchasedFooter{
    margin-bottom:60px
    }

.purchaseDoneImgContainer{
    margin-top: 30px;
    margin-bottom: 30px;
}
.purchaseDonwImage{
    height: 22vh
}
