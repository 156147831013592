.footer-container {
  height: 40px;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(24px);
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 25px;
  font-size: 15px;
}

.footer-icon {
  height: 15px;
  width: 15px;
}

.footer-icon {
  margin: 15px 0px;
}

.footer-hash-tag {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 15px;
}
