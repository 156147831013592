.App-logo {
  width: 20vmin;
}
.homeLogoContainer {
  padding-top: 40px;
}
.buyButtons {
  margin-right: 5px;
}

.homePage {
  min-height: 100vh;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);

  background: url('../../../assets/images/background1.png') no-repeat center
    center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.homePageWithVideo {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  background-image: url('../../../assets/images/background4.png');
  background-position: unset;
  background-size: cover;
  flex: 1;
  background-repeat: no-repeat;
  flex-direction: column;
}

.Streaming {
  border-bottom: 1px solid var(--Streaming);
  color: white;
  padding-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 4px;
}

.Streaming1 {
  color: white;
  border-bottom: 1px solid var(--Streaming);
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 4px;
  font-style: normal;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0;
}

.timerMain {
  display: flex;
  flex-direction: row;
}

.timerSub {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65px;
}

.time {
  color: white;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 4px;
  font-family: Roboto, sans-serif;
}

.timeTitle {
  color: white;
  font-size: 11px;
  padding-top: 5px;
  font-weight: 300;
  letter-spacing: 7px;
  font-family: Roboto, sans-serif;
}
.fa {
  font-size: 30px;
  width: 30px;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  padding: 5px;
  font-family: Roboto, sans-serif;
}

.fa-facebook:hover {
  background-color: #3b5998;
  padding: 5px;
  border-radius: 6px;
}

.fa-twitter:hover {
  background-color: #55acee;
  padding: 5px;
  border-radius: 6px;
}

.fa-instagram:hover {
  background-image: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  padding: 5px;
  border-radius: 6px;
}

.bottomText {
  color: white;
  font-size: 15px;
  font-weight: bolder;
}

.fa-facebook {
  background: transparent;
  color: white;
}

.fa-twitter {
  background: transparent;
  color: white;
}

.fa-instagram {
  background: transparent;
  color: white;
}
.button-ex {
  display: inline-block;
  flex-direction: row;
  margin: 15px 0px 15px 0px;
}

.videoPlayerModal {
  outline: none;
  height: 100%;
  width: 100%;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  display: flex;
  position: relative;
}

.trailerModal {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.trailerContainer {
  width: 60%;
  border: 1px solid white;
  position: relative;
}

.trailer-play-button {
  position: absolute;
  bottom: 45%;
  right: 42%;
  left: 42%;
  top: 42%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  cursor: pointer;
}
.trailer-play-button:active {
  color: #fff;
}

.trailer-reserveContainer {
  position: absolute;
  bottom: -1px;
  right: 0px;
  border-bottom: 1px solid white;
  left: 0px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  top: 0px;
  z-index: 10;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 1) 15%,
    rgba(0, 0, 0, 0.4)
  );
}

.trailerButtons {
  padding: 7% 5%;
}
.buyNowDescription {
  color: white;
  font-size: 18px;
  text-align: center;
}

.trailerButtonContainer {
  padding: 5px;
  width: 170px;
}
.trailer-button-container {
  padding-bottom: 15px;
}

@media (max-width: 1024px) {
  .trailer-reserveContainer {
    right: -1px;
    border-right: 1px solid white;
  }
  .trailer-play-button {
    bottom: 50%;
    right: 40%;
    left: 40%;
    top: 30%;
  }
}

@media (max-width: 900px) {
  .trailer-reserveContainer {
    right: -1px;
    border-right: 1px solid white;
  }
  .trailer-play-button {
    bottom: 50%;
    right: 40%;
    left: 40%;
    top: 30%;
  }
}

@media (max-width: 700px) {
  .App-logo {
    width: 24vmin;
  }
  .buyNowDescription {
    font-size: 16px;
  }
  .trailer-play-button {
    bottom: 50%;
    right: 40%;
    left: 40%;
    top: 30%;
  }
}

@media (max-width: 500px) {
  .trailerContainer {
    width: 100%;
    position: relative;
  }
  .App-logo {
    width: 30vmin;
  }
}

@media (max-width: 450px) {
  .timerSub {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50px;
  }
  .App-logo {
    width: 45vmin;
  }
  .time {
    color: white;
    font-size: 38px;
    font-weight: 600;
    letter-spacing: px;
    font-family: Roboto, sans-serif;
  }
  .timeTitle {
    color: white;
    font-size: 15px;
    padding-top: 5px;
    font-weight: 200;
    letter-spacing: 2px;
    font-family: Roboto, sans-serif;
  }
  .trailerButtonContainer {
    width: 150px;
  }
  .buyNowDescription {
    font-size: 15px;
  }
  .trailer-play-button {
    bottom: 57%;
    right: 40%;
    left: 40%;
    top: 17%;
  }
}

@media (max-width: 300px) {
  .trailer-play-button {
    bottom: 68%;
    right: 40%;
    left: 40%;
    border-radius: 3px;
    top: 10%;
  }
}
