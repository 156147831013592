.preBookingModal {
  outline: none;
  background-color: var(--primaryBackground);
  width: 80%;
  height: 80%;
  display: flex;
  border-radius: 5px
}

.reserveContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.selectedColor{
    color: var(--PrimaryText)
}
.notSelectedColor{
    color: var(--SecondaryText)
}
.itemSelected{
    border: 1px solid var(--SelectedBorder);
}
.itemNotSelected{
    border: 1px solid #969696
}
.reserIcon{
    height: 150px
}

.backIcon {
  position: absolute;
  top: 0px;
  bottom: 0px;
  z-index: 5px;
  left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preBookingContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.preBookingContainer {
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 20px;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 60px;
}
.buttonComp {
  width: 300px;
}

@media (max-width: 650px) {
  .backIcon {
    left: 10px;
  }
  .preBookingContainer {
    padding-top: 10px;
  }
  .preBookingModal {
    width: 100%;
    height: 100%;
    border-radius: 0px
  }
  .buttonContainer {
    margin-bottom: 10px;
  }
  .buttonComp {
    width: 65%;
  }
}
