.selectedDropDownMenu{
    border-bottom:1px solid lightgray;
    padding-top:5px;
    padding-bottom:5px;
    height:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    background-color: #282DDB;
  }
  .dropDownMenu {
    background-color:#ffffff;
    border-bottom:1px solid lightgray;
    padding-top:5px;
    padding-bottom:5px;
    height:30px;
    display:flex;
    justify-content:center;
    align-items:center;
    cursor:pointer
  }
  .planPriceContainer{
      color:#000000;
      margin: 0px;
    flex:1;
    font-weight:600;
    padding-left:10px;
    padding-right:10px;
  }
  .selectedPlanPriceContainer{
    color:#ffffff;
    margin: 0px;
  flex:1;
  font-weight:600;
  padding-left:10px;
  padding-right:10px;
  }
  .selectedPlanTitleContainer{
    flex:1;
    text-align:center;
    font-weight:600;
    color:#ffffff
  }
  .planTitleContainer{
    flex:1;
    text-align:center;
    font-weight:600;
    color:gray
  }
  .dropDownMenu:hover{
    background-color:#282DDB
  }
  .dropDownMenu:active{
    background-color:#282DDB
  }
  .selectedDropDownMenu:active{
    background-color:#282DDB
  }
  .dropDownMenu:hover .planPriceContainer{
    color:#ffffff
  }
  .dropDownMenu:hover .planTitleContainer{
    color:#ffffff
  }