.inputLabel {
  color:var(--PrimaryText);
  font-weight: 500;
  font-size: 17px;
}
.resetPassworError {
  color: red;
  margin: 0px;
  padding: 0px;
}
