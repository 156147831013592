.editFormContainer {
  margin-left: 10px;
  margin-right: 10px;
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.avatarImage {
  height: 16vh;
}
.chooseAvatarButton {
  padding-top: 30px;
  padding-bottom: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.editInputContainer {
  padding-bottom: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.editFormInput{
  width: 85%;
}
.dropDownSelectContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 85%;
}
.codeSelect {
  width: 35%;
}
.mobileNumberInput {
  width: 60%;
  padding-left: 5%;
}
.formButtons {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.editAccountFooterContainer {
  padding-bottom: 15%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 85%;
}
.editAccountFooterContainer-button {
  flex: 1;
  padding-right: 5px;
}
