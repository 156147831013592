.desktopTabletView {
  position: relative;
  flex: 1;
}
.mobileView {
  position: relative;
  flex: 1;
  width: 100%;
  margin-bottom: 15px;
}
.mobilePlanName {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 35px;
  background-color: var(--SelectedPlan);
}
.closedPlan {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  height: 35px;
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--unSelectedPlan);
}
.closedPlanName {
  flex: 1px;
  align-items: center;
  display: flex;
  font-weight: 700;
  color: var(--PrimaryText);
}
.couponAppliedText {
  align-items: center;
  display: flex;
  font-weight: 700;
  color: var(--PrimaryText);
}

.couponAppliedPrice {
  padding-right: 10px;
  color: var(--PrimaryText);
  font-weight: 500;
  text-decoration: line-through;
  font-size: 15px;
}
.withoutCouponText {
  align-items: center;
  display: flex;
  font-weight: 700;
  color: var(--PrimaryText);
}

.mobileToonifyContainer {
  height: 35px;
  color: white;
  font-weight: 600;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fec874;
}
.mobileToonifyContainerDark {
  height: 35px;
  color: white;
  font-weight: 600;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fe9b00;
}

.planContent {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.planContainer {
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0px 30px 0px 0px;
  cursor: pointer;
}
.selectedPlanBorder {
  border: 1px solid var(--SelectedPlan);
}
.unSelectedPlanBorder {
  border: 1px solid var(--unSelectedPlan);
}
.planNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #ffffff;
  font-weight: 600;
}
.MobilePlanNameContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  color: #ffffff;
  font-weight: 600;
}
.priceContainer {
  font-weight: 700;
  font-size: 25px;
  color: #ffffff;
  text-align: center;
  margin: 40px 0px 10px 0px;
}
.mobilePriceContainer {
  font-weight: 800;
  font-size: 20px;
  color: #000000;
  text-align: center;
  margin: 10px 0px 10px 0px;
}

.ReactCollapse--collapse {
  transition: height 800ms;
}

.qualityContainer {
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  padding: 20px 0px 20px 0px;
}

.mobileQualityContainer {
  font-weight: 600;
  font-size: 19px;
  color: #a2a2a2;
  text-align: center;
  padding: 10px 0px 10px 0px;
}

.deviceContainer {
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.deviceTitle {
  font-size: 8px;
  font-weight: 600;
  padding-top: 4px;
  color: #a2a2a2;
}

.promoText {
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  margin: 30px;
  cursor: pointer;
  color: var(--PrimaryText);
}

.appliedCodeText {
  font-weight: 600;
  font-size: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--TextLink);
  margin: 30px;
}

.couponContainer {
  outline: none;
  background-color: var(--primaryBackground);
  width: 550px;
  padding: 50px 0px 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 5px;
}
.toonifyModal {
  outline: none;
  padding: 0px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.couponModalTitle {
  display: flex;
  justify-content: center;
  align-items: center;
}
.coupon-modal-title{
  color:var(--PrimaryText);
  font-size:17px;
  font-weight: 600;
  padding-bottom: 10px;
}
.textFieldContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 250px;
}

.promoText:active {
  color: '#000000';
}
.toonifyContainer {
  position: absolute;
  left: 0px;
  right: 0px;
  top: -40px;
  z-index: 2;
  height: 40px;
  border-top-right-radius: 2px;
  border-top-left-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
  cursor: pointer;
}
.shineRight {
  position: absolute;
  right: -27px;
  top: -43px;
  z-index: 2;
}
.shineLeft {
  position: absolute;
  left: -27px;
  top: -43px;
  z-index: 2;
}
.toonifyImage {
  position: absolute;
  right: -220px;
  top: -40px;
  z-index: 2;
  height: 200px;
  width: 200px;
}

.toonifyContainer:hover {
  box-shadow: 0px 14px 14px rgba(0, 0, 0, 0.1);
  justify-content: 'center';
  align-items: center;
}

/* After selection */
.mobileQualityContainer-selected {
  color: #ffffff;
  font-weight: 600;
  font-size: 19px;
  text-align: center;
  padding: 10px 0px 10px 0px;
}
.qualityContainer-selected {
  font-weight: 500;
  font-size: 17px;
  text-align: center;
  padding: 20px 0px 20px 0px;
}
.planNameContainer-selected {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-weight: 600;
  height: 50px;
}
.planContainer-selected {
  cursor: pointer;
  width: 100%;
  /* height: 50px; */
  box-sizing: border-box;
  border-radius: 10px;
  margin: 0px 30px 0px 0px;
}

.coupon-modal {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
}

.select-country { 
  width: '100%';
  color: 'black';
  background-color: white;
}

@media (max-width: 650) {
  .couponContainer {
    width: 250;
  }
}
