.desktop-container {
  flex: 1;
  display: flex;
  padding: 0px 17%;
}
.topMenu {
  color: #ffffff;
  text-decoration: none;
  font-weight: 500;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0px 15px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid transparent;
}

.userName {
  flex: 0.6;
  justify-content: center;
  align-items: center;
  display: flex;
  color: white;
  font-size: 22px;
}

ul {
  padding-top: 20px;
  padding-bottom: 20px;
}

.buyNowButton {
  color: #ffffff;
  text-decoration: none;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  margin: 0px 15px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  background: rgba(64, 155, 206, 0.2);
  backdrop-filter: blur(54px);
  padding: 7px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buyNowButton:hover {
  background-color: rgb(69, 156, 204);
}

.buyNowText {
  padding: 0px 10px;
}
.loginText {
  margin-left: 10px;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
  padding: 5px 10px;
  border: 1px solid transparent;
}
.loginText:hover {
  border: 1px solid var(--PrimaryButton);
  border-radius: 5px;
}
.avatarButton {
  padding: 0px;
  margin: 0px 6px;
}

.topMenu:hover {
  border: 1px solid var(--PrimaryButton);
  border-radius: 5px;
}

.topMenuActive {
  color: white;
  padding: 7px;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  text-transform: uppercase;
  margin: 0px 15px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
}
.mobile-logo {
  padding-bottom: 2px;
  padding-right: 12px;
}
.mobile-logo-container {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-end;
  display: flex;
}

.image {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding-top: 5px;
}

.image-size {
  width: 80%;
  padding-bottom: 2px;
}

.mintrio-title {
  color: var(--PrimaryText);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 700;
  padding: 0px 15px;
}

.img__description {
  position: absolute;
  top: 0;
  left: 70px;
  color: #cbcbcb;
  font-size: 12px;
  font-weight: bold;
  align-self: flex-start;
  margin-top: 20px;
  font-family: 'Segoe UI Semibold', monospace;
  visibility: hidden;
  opacity: 0;

  /* transition effect. not necessary */
  transition: opacity 0.4s, visibility 0.4s;
}

.image:hover .img__description {
  visibility: visible;
  opacity: 1;
}

.image:focus .img__description {
  visibility: visible;
  opacity: 1;
}
.dropdown-menu {
  font-weight: bold;
  font-size: 12px;
}

.left-menu {
  padding: 15px;
  padding-left: 30px;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.01em;
}
.right-menu {
  padding: 13px;
  padding-right: 30px;
  text-align: right;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
  letter-spacing: 0.01em;
  background-color: inherit;
  color: #cbcbcb;
}
.profileDrawerImage {
  flex: 0.4;
  justify-content: center;
  align-items: center;
  display: flex;
}

.avatarSection {
  background-color: rgba(162, 162, 162, 0.2);
  height: 146px;
  display: flex;
}
.MuiList-padding {
  padding-top: 0px;
}
.list-left {
  padding-top: 0px;
}

.MuiMenu-paper {
  background-color: blue;
}
.topArrow {
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid white;
}
.customMenuContainer {
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid lightgray;
}
.customMenuItem {
  padding: 3px 10px;
  background-color: #ffffff;
  display: flex;
  cursor: pointer;
}

.menuListIconContainer {
  padding-right: 10px;
  padding-left: 10px;
}
.menuListIcon {
  color: #141af5;
  font-size: 6px;
}
.notificationTitleContainer {
  padding-right: 10px;
}
.notificationTime {
  color: gray;
  font-size: 8px;
  font-weight: 600;
  padding-top: 3px;
  padding-bottom: 3px;
}
.notificationTitle {
  font-size: 11px;
  font-weight: 600;
}

.menuTitle {
  font-weight: 600;
  font-size: 11px;
  padding: 5px 0px;
  color: #000000;
}
.notificationBellCircle {
  position: absolute;
  z-index: 3;
  top: 2px;
  right: 13px;
  height: 8px;
  width: 8px;
  border-radius: 10px;
  background-color: white;
  border: 1px solid #282ddb;
}

/* Notification Modal Sytles */
.notification-mainContainer {
  flex: 1;
  height: 100%;
  width: 100%;
  outline: none;
  background-color: #151429;
}
.notification-headerContainer {
  border-bottom: 1px solid #3c3c63;
  padding: 24px 0px;
  position: relative;
}
.notifcation-backButton {
  position: absolute;
  left: 20px;
  top: 30%;
  z-index: 2;
}
.notification-headerText {
  color: white;
  text-align: center;
  font-size: 16px;
  font-weight: 900;
  text-transform: uppercase;
}
.notification-item {
  border-bottom: 1px solid #3c3c63;
  padding: 20px 0px;
  display: flex;
}
.notification-dot-container {
  flex: 0.25;
  display: flex;
  justify-content: center;
  align-items: center;
}
.notificaiton-itemTitleContainer {
  flex: 0.75;
  padding-right: 20px;
}
.notification-item-title {
  padding: 0px;
  margin: 0px;
  color: #64648b;
  font-size: 14px;
  font-weight: 500;
}
.notification-time {
  color: #64648b;
  font-size: 10px;
  font-weight: 700;
  padding-top: 7px;
}

.unReadnotification-time {
  color: #a2a2a2;
  font-size: 10px;
  font-weight: 700;
  padding-top: 7px;
}
.notification-dot {
  font-size: 15px;
  color: #64648b;
}
.unReadNotification-dot {
  font-size: 15px;
  color: #282ddb;
}
.unReadnotification-item-title {
  padding: 0px;
  margin: 0px;
  color: white;
  font-size: 14px;
  font-weight: 700;
}
/* -- */

.customMenuItem:hover {
  background-color: #141af5;
}
.customMenuItem:active {
  background-color: #141ac0;
}
.customMenuItem:hover .menuListIcon {
  color: #ffffff;
}
.customMenuItem:hover .notificationTitle {
  color: #ffffff;
}
.customMenuItem:hover .notificationTime {
  color: #ffffff;
}
.customMenuItem:hover .menuTitle {
  color: #ffffff;
}

.MuiMenu-list {
  background-color: transparent;
}

@media (min-width: 960px) and (max-width: 1090px) {
  .img__description {
    font-size: 9px;
  }
  .desktop-container {
    flex: 1;
    display: flex;
    padding: 0px 10%;
  }
}
@media (max-width: 960px) {
  .desktop-container {
    display: none;
  }
}
@media (max-width: 375px) {
  .img__description {
    display: none;
  }
}

@media (max-width: 600px) {
  .image {
    display: none;
  }
  .notificationSection {
    padding-right: 10px;
  }
  .loginText {
    font-weight: 900;
    font-size: 15px;
  }
}
