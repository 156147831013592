body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primaryBackground: #151429;
  --SelectedBackground: #64648b;
  --SelectedDarkBackground: #3c3c63;
  --SelectedBorder: #282ddb;
  --PrimaryButton: #282ddb;
  --SecondaryButton: #3c3c63;
  --PrimaryButtonHover: #141af5;
  --SecondaryButtonHover: #ffffff;
  --ButtonText: #ffffff;
  --SecondaryHoverButtonText: #000000;
  --PrimaryText: #ffffff;
  --SecondaryText: #9d9d9d;
  --SelectedPlan: #282ddb;
  --unSelectedPlan: #1e2082;
  --TextLink: #00fbaa;
  --Streaming: #ff0000;
}

.fullScreen {
  width: 100%;
  height: 100%;
  display: flex;
}
.flexCenteredConatiner {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}
.columnCenteredConatiner {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.columnConatiner {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.centeredContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullContainer {
  flex: 1;
  display: flex;
}
.flex {
  flex: 1;
}
.fullColumnContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.loggedInTitleTitle {
  font-weight: 600px;
  font-size: 15px;
}

.modalContainer {
  outline: none;
  background-color: var(--primaryBackground);
  width: 80%;
  height: 80%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
}
.navBarPostionContainer {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 3;
}
.pageWithBackgound {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: var(--primaryBackground);
}
.button-title {
  justify-content: center;
  display: flex;
  align-items: center;
  font-size: 14px;
}

@media (max-width: 650px) {
  .modalContainer {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
  position: relative;
}

.padding-30 {
  padding: 30px;
}
.padding-10 {
  padding: 10px;
}

@media (max-width: 650px) {
  .modalContainer {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 450px) {
  .button-title {
    font-size: 12px;
  }
}
