.verifyPageMain{
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1
}
.verifyMobileTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.verifyMobileTitleText {
  color:var(--PrimaryText);
  margin: 0px;
}
.verifyMobileSubTitleText {
  color:var(--PrimaryText);
  font-size: 15px;
  font-weight: 400;
}
.codeInput {
  width:300px;
  margin-top: 30ps;
  margin-bottom: 30px;
}
.sendAgainText {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-top: 10px;
  color: var(--PrimaryText);
}
.sendAgainIcon {
  font-size: 16px;
  padding: 4px 7px 0px 0px;
  color: var(--PrimaryText);

}
.verifyButtonContaienr {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.sendAgainText:active {
  color: gray;
}
.errorMessage {
  margin: 0px;
  text-align: center;
  color: red;
  font-size: 14px;
}
