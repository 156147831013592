.headerContainer {
    position: relative;
    padding: 5% 0px 1% 0px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
    text-align: center
  }
  
  
  @media (max-width: 500px) {
    .headerContainer {
      padding: 5% 25% 4% 25%;
    }
  }
  