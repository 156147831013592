.castContainer {
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  position: relative;
}
.titleLayer {
  position: absolute;
  top: 15%;
  left: 0px;
  /* bottom: 0px; */
  z-index: 2;
  display: flex;
  align-items: center;
}
.detailLayer {
  position: absolute;
  top: 65%;
  left: 85px;
  bottom: 0px;
  z-index: 2;
  display: flex;
  color: white;
  width: 550px;
  line-height: 153.8%;
  font-size: 16px;
}
.picturesLayer {
  position: absolute;
  right: 0px;
  bottom: -3px;
}
.rightLogo {
  position: absolute;
  top: 40px;
  right: 20px;
  z-index: 3;
}
.blurredLayer {
  z-index: 2;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
}
.gradientLayer {
  background-image: linear-gradient(to left bottom, black 1%, transparent);
  z-index: 1;
  top: 0px;
  right: 0px;
  bottom: 50%;
  width: 100%;
}
.footerLayer {
  position: absolute;
  bottom: 15px;
  left: 0px;
  right: 0px;
  z-index: 3;
  display: flex;
  color: white;
  /* padding-bottom: 25; */
  /* height: 90; */
  padding-right: 30px;
}
.footerIconText {
  flex: 1;
  display: flex;
}
.socialIconsContainer {
  /* flex: 1; */
  padding-left: 85px;
  display: flex;
  align-items: center;
  display: flex;
}
.footer-iconSize {
  height: 25px;
  padding: 10px;
}
.nextPrevButtonContainer {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.ishratMadeInChina {
  padding-left: 7%;
  flex: 1;
  font-weight: bold;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.nextPrevButton {
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(13px);
  border-radius: 3px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  cursor: pointer;
}

.nextPrevButton:active {
  background-color: rgba(255, 255, 255, 0.1);
}

.unselectedCast {
  position: relative;
}

.selectedCast:hover img {
  transform-origin: right bottom;
}
.titleImage {
  width: 60%;
  transition: all 1500ms ease-in-out;
}
.ishratLogoPic {
  height: 150px;
}
.castImageItem {
  transform-origin: right bottom;
}

@keyframes scaleDown {
  from {
    transform: scale(0.87) translate(0px, 0px);
  }
  to {
    transform: scale(0.55) translate(250px, 0px);
  }
}

@keyframes scaleUp {
  from {
    transform: scale(0.55) translate(250px, 0px);
  }
  to {
    transform: scale(0.87) translate(0px, 0px);
  }
}


@media (max-width: 1200px) {
  .titleImage {
    width: 50%;
  }
  .rightLogo {
    position: absolute;
    top: 10%;
    right: -20px;
    z-index: 3;
  }
  .detailLayer {
    top: 43%;
    left: 15%;
    right: 15%;
    font-size: 13px;
    width: 70%;
    text-align: center;
  }
  .footerIconText {
    flex-direction: column;
  }
  .ishratMadeInChina {
    padding-left: 60px;
  }
  .picturesLayer {
    left: 15%;
    justify-content: center;
    align-items: center;
    right: 0px;
  }
  .footerIconText {
    display: none;
  }
  .footerLayer {
    padding: 0px 15px;
  }
  .nextButtonContainer {
    flex: 1;
  }
  .previousButtonContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .castImageItem {
    transform-origin: bottom;
  }

  @keyframes scaleDown {
    from {
      transform: scale(0.5) translate(0px, 0px);
    }
    to {
      transform: scale(0) translate(0px, 0px);
    }
  }

  @keyframes scaleUp {
    from {
      transform: scale(0) translate(0px, 0px);
    }
    to {
      transform: scale(0.5) translate(0px, 0px);
    }
  }
}
@media (max-width: 900px) {
  .titleImage {
    width: 50%;
  }
  .rightLogo {
    position: absolute;
    top: 10%;
    right: -20px;
    z-index: 3;
  }
  .detailLayer {
    top: 43%;
    left: 15%;
    right: 15%;
    font-size: 12px;
    width: 70%;
    text-align: center;
  }
  .footerIconText {
    flex-direction: column;
  }
  .ishratMadeInChina {
    padding-left: 60px;
  }
  .picturesLayer {
    left: 15%;
    justify-content: center;
    align-items: center;
    right: 0px;
  }
  .footerIconText {
    display: none;
  }
  .footerLayer {
    padding: 0px 15px;
  }
  .nextButtonContainer {
    flex: 1;
  }
  .previousButtonContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  .castImageItem {
    transform-origin: bottom;
  }

  @keyframes scaleDown {
    from {
      transform: scale(0.4) translate(0px, 0px);
    }
    to {
      transform: scale(0) translate(0px, 0px);
    }
  }

  @keyframes scaleUp {
    from {
      transform: scale(0) translate(0px, 0px);
    }
    to {
      transform: scale(0.4) translate(0px, 0px);
    }
  }
}

@media (max-width: 430px) {
  .titleLayer {
    top: 10%;
  }

  .rightLogo {
    position: absolute;
    top: 10%;
    right: 0px;
    z-index: 3;
  }
  .ishratLogoPic {
    height: 110px;
  }
  .detailLayer {
    top: 38%;
    left: 10%;
    right: 10%;
    font-size: 12px;
    width: 80%;
    text-align: center;
  }
}
