.modalHeaderContainer {
  position: relative;
  padding: 5% 0px 3% 0px;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
}

.backIconContainer {
  position: absolute;
  top: 20%;
  left: 4%;
  z-index: 2;
}
.backIconColor {
  color: white;
}

@media (max-width: 500px) {
  .backIconContainer {
    top: 30%;
  }
  .modalHeaderContainer {
    padding: 0px 0px 10px 0px;
    padding: 5% 0px 5% 0px;
  }
}
@media (max-width: 500px) {
  .backIconContainer {
    top: 30%;
  }
  .modalHeaderContainer {
    padding: 0px 0px 10px 0px;
    padding: 7% 0px 7% 0px;
  }
}
