.externalPaymentDetail {
  flex: 1;
  padding: 30px;
  display: flex;
}
.marginContainer50 {
  margin-top: 50px;
}
.continuePaymentButton {
  margin: 50px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paymentSubHeading {
  color: var(--SecondaryText);
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
}
.learnMoreText {
  color: #2daae1;
  text-decoration: none;
}
.paymentButtonText {
  color: var(--SecondaryText);
  font-size: 15px;
  margin-top: 10px;
  text-align: center;
}
.jazzcashInput{
  width: 300px;
  margin-bottom: 20px;
  color:white;
  text-align: center;
  font-size: 12px;
}

.payment-back-button{
  position:absolute;
  left:5%;
  top:50%;
  z-index:2;
  color:white;
  cursor:pointer;
}
.payment-back-button :active{
  color:lightgray;
}
