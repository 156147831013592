.visaButtonContainer{
    margin-top:50px;
    margin-bottom:50px;
    display:flex;
    justify-content:center;
    align-items:center;
}

.visaForm{
    flex: 1;
              padding: 30px;
              display: flex;
              justify-content: center;
              flex-direction: column;
}

.payment-back-button{
    position:absolute;
    left:5%;
    top:50%;
    z-index:2;
    color:white;
    cursor:pointer;
  }
  
.payment-back-button :active{
    color:lightgray;
  }

    @media (max-width: 650px){
        .visaButtonContainer{
            margin-bottom:10px;
        }
}

