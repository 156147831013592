.mintrioBackground {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('../../../assets/images/mintrioBackground.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.heading {
  color: #ffffff;
  margin: 0px;
  padding: 0px;
  text-align: center;
  font-size: 35px;
  font-weight: 600;
  padding: 20px;
}

.mintrioLogo {
  height: 70px;
}
.logoContainer {
  justify-content: flex-end;
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 0.3;
}
.formContainer {
  flex: 0.7;
  width: 100%;
  display: flex;
}
.welcomeBackText {
  color: white;
  font-weight: 800;
  font-size: 30px;
}

.signInText {
  color: white;
  font-size: 15px;
  font-weight: 300;
  padding-top: 5px;
}

@media (max-width: 650px) {
  .heading {
    color: #ffffff;
    margin: 0px;
    padding: 0px;
    text-align: center;
    font-size: 30px;
    padding: 20px;
  }
  .welcomeBackText {
    color: white;
    font-weight: 700;
    font-size: 25px;
  }
  .signInText {
    color: white;
    font-size: 14px;
    font-weight: 300;
  }
}
.loginFormContent {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.subHeadingContainer {
  width: 70%;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.formInputContainer {
  width: 70%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.loginError {
  color: red;
  text-align: center;
  font-size: 14px;
}

.forgetPasswordText {
  width: 70%;
  padding-bottom: 10px;
  color: white;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: flex-end
}
.loginButtonContainer {
  width: 70%;
  padding-bottom: 10px;
  margin-top: 10px;
  color: white;
  font-size: 14;
  text-align: right;
}
.dividerContainer {
  width: 70%;
  padding-bottom: 10px;
  margin-top: 10px;
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.dividerLine {
  background-color: #565656;
  flex: 0.4;
  height: 1px;
}
.orText {
  flex: 0.3;
  color: #565656;
  font-size: 15px;
  font-weight: 600;
  text-align: center;
}
.forgetPasswordText:active {
  color: gray;
}

@media (max-width: 390px) {
  .heading {
    color: #ffffff;
    margin: 0px;
    padding: 0px;
    text-align: center;
    font-size: 25px;
    padding: 20px;
  }
  .welcomeBackText {
    color: white;
    font-weight: 700;
    font-size: 25px;
  }
  .signInText {
    color: white;
    font-size: 1px;
    font-weight: 300;
  }
}
