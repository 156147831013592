.navbar{
    flex-direction: row;
    background: black;
}

hide-scroll{
    overflow: hidden;
}
hide-scroll::-webkit-scrollbar {
    display: none;
}
