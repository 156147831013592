.loggedInBackground {
  flex: 1;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('../../../assets/images/background3.png');
  background-position: center;
  background-size: cover;
}
.mobileLoggedinContainer {
  flex: 1px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.linearProgressBar {
  width: 90%;
  margin-top: 30px;
}
.linearProgressBarDesktop {
  width: 100%;
}

.MuiLinearProgress-barColorPrimar {
  color: #ff0000;
  background-color: #ff0000;
}
.buttonAndProgressContainerMobile {
  display: flex;
  width: 90%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blurredContainerMobile {
  flex: 1;
  margin-top: 80%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(7px);
}
.mobileViewContentLogin {
  flex: 0.9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.buttonContainerMobileView {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
}
.bottomContainerMobile {
  flex: 0.1;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.desktopLoginMain {
  justify-content: center;
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}
.ishratSideLogo {
  height: 220px;
  box-shadow: 0px 35px 54px 10px rgba(0, 0, 0, 0.35);
}
.detailContainer {
  flex: 0.6;
  flex-direction: column;
  display: flex;
  padding-left: 20px;
}
.counterTitleContainer {
  padding-bottom: 20px;
  flex: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.buttonAndProgressContainerDesktop {
  flex: 0.4;
  display: flex;
  width: 100%;
  flex-direction: column;
}

.resumePlayButtonContainer {
  flex: 1;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.thumbsButton {
  height: 30px;
  width: 30px;
}
.thumbsButtonContainer {
  width: 30px;
  margin-left: 20px;
  margin-right: 20px;
}

.bottomContainerDesktop {
  position: absolute;
  left: 0px;
  bottom: 0px;
  right: 0px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.playOnTabletDesktopContainer {
  outline: none;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}
.watchMovieText {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  padding: 0px;
  margin: 0;
}

.getFreeAppButton {
  padding-top: 20px;
  width: 65%;
}

@media (max-width: 450px) {
  .loggedInBackground {
    background-image: url('../../../assets/images/mobile/loggedInBackground.png');
  }
}
