.game-body {
  min-height: 93vh;
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  background-image: url('../../../assets/images/background-game.png');
  background-position: unset;
  background-size: cover;
  background-repeat: no-repeat;
}

.left-container {
  flex: 1;
  min-height: 100vh;
  background-image: linear-gradient(to right, black 30%, transparent);
  align-items: center;
  padding-left: 30px;
  padding-right: 30px;
}

.game-title {
  color: white;
  letter-spacing: 0;
  text-transform: uppercase;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 40px;
  padding-top: 40px;
}

.game-detail {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  display: flex;
  align-items: center;
  color: #ffffff;
  text-shadow: 0 4px 34px #000000;
}

.game-image {
  height: 19vmin;
  pointer-events: none;
  margin-right: 20px;
  margin-left: 20px;
  margin-top: 5px;
}

.download-game {
  color: white;
  font-size: 15px;
  font-weight: 900;
  font-family: Roboto, sans-serif;
  pointer-events: none;
  text-transform: uppercase;
  text-align: center;
  padding-top: 20px;
}

.download-game-link {
  color: white;
  font-size: 12px;
  font-weight: 100;
  font-family: Roboto, sans-serif;
  pointer-events: none;
  text-transform: uppercase;
  text-align: center;
  padding-bottom: 10px;
}

.button-class {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-button {
  padding: 20px;
  width: 100px;
}
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 450px) and (min-width: 200px) {
  .game-title {
    text-align: left;
    padding-bottom: 10px;
    margin: 0px;
    font-size: 33px;
    font-weight: 900;
  }
  .game-detail {
    text-align: left;
    font-size: 16px;
    line-height: 120.8%;
    font-size: 16px;
    font-weight: 400;
  }
  .left-container {
    padding-bottom: 0px;
    padding-right: 0px;
    padding-left: 0px;
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
    background-image: linear-gradient(to top, black 30%, transparent);
  }
  .game-image {
    height: 20vmin;
    pointer-events: none;
    margin-top: 5px;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .download-game {
    text-align: center;
  }
  .download-game-link {
    text-align: center;
  }
  .button-class {
    flex-direction: column;
    margin-bottom: 20px;
  }
}

@media (max-width: 770px) and (min-width: 451px) {
  .game-title {
    text-align: center;
  }
  .game-detail {
    text-align: center;
  }
  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .download-game {
    text-align: center;
  }
  .download-game-link {
    text-align: center;
  }
  .button-class {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0%;
  }
  .game-image {
    margin-right: 10px;
    margin-left: 10px;
  }
}
