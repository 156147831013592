.registrationMainContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
.signupInputContainer {
  width: 85%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.dobInputContainer {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  width: 85%;
}
.mobileInputContainer {
  display: flex;
  justify-content: space-between;
  width: 85%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.selectedAvatarContainer {
  width: 85%;
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
}
.selectedAvatar {
  height: 140px;
}

.MuiGridListTile-root {
  height: 100;
}
.MuiGridListTile-tile {
  text-align: center;
}
.formGrid {
  flex: 1;
  padding: 0px;
  margin: 0px;
}
.avatarListModal {
  outline: none;
  background-color: white;
  overflow: hidden;
  border-radius: 10px;
  background: #151429;
  display: flex;
  flex-direction: column;
  padding: 40px;
  margin: 30px;
}
.signUpError {
  color: red;
  font-size: 14px;
  text-align: center;
}
.signupButtonContainer-error {
  padding-top: 0px;
  width: 90%;
  padding-bottom: 10px;
  justify-content: center;
  display: flex;
}
.signupButtonContainer {
  padding-top: 30px;
  width: 90%;
  padding-bottom: 10px;
  justify-content: center;
  display: flex;
}

.MuiList-root {
  max-height: 200px;
}
.MuiPaper-root
  .MuiMenu-paper
  .MuiPopover-paper
  .MuiPaper-elevation8
  .MuiPaper-rounded {
  top: 50%;
}

.avatarButtons{
  padding: 10px 
}

.registrationDetailPage{
  overflow:auto
}
.MuiSelect-nativeInput{
  color:'white'
}

.avatarModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(30px);
}

@media (max-width: 450px) {
  .selectedAvatar {
    height: 120px;
  }
  .avatarListModal {
    padding: 10px 0px 10px 0px;
    margin: 10px;
  }

}
