.marginContainer{
    margin-top:30px
}
.nameInputGrid{
    margin: 0px
}
.emailInputGrid{
    margin-top: 20px;
}
.friendDetailFooter{
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.friends-detail-description{
    color:var(--PrimaryText);
    text-align:center
}