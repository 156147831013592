.playerContainer {
  height: 100%;
  width: 100%;
}
.video-js .vjs-title-bar {
  color: white;
  display: none;
  font-size: 2em;
  padding: 0.5em;
  position: absolute;
  top: 3vh;
  left: 4vh;
  height: 40px;
  width: 40px;
  background-image: url('../../assets/images/logo.svg');
  background-repeat: no-repeat;
}

.video-js .vjs-close {
  position: absolute;
  font-size: 9px;
  height: 25px;
  top: 3vh;
  right: 3vh;
}

.video-js.vjs-paused.vjs-has-started .vjs-title-bar,
.video-js.vjs-user-active.vjs-has-started .vjs-title-bar {
  display: block;
  height: 100px;
}

.video-js .vjs-close:before {
  color: rgb(255, 255, 255, 0.7);
  font-family: 'Font Awesome 5 Free';
  content: '\f057';
  display: block;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 600;
  cursor: pointer;
}

.video-js.vjs-paused.vjs-has-started .vjs-close,
.video-js.vjs-user-active.vjs-has-started .vjs-close {
  display: block;
}

.vjs-matrix.video-js {
  color: #00ff00;
}

/* Change the border of the big play button. */
.vjs-matrix .vjs-big-play-button {
  border-color: #00ff00;
}

/* Change the color of various "bars". */
.vjs-matrix .vjs-volume-level,
.vjs-matrix .vjs-play-progress,
.vjs-matrix .vjs-slider-bar {
  background: #00ff00;
}

.video-js .vjs-time-control {
  position: absolute;
  top: -22px;
  right: -20px;
  z-index: 2;
}

.video-js .vjs-remaining-time {
  display: flex;
  font-size: 12px;
}

.video-js .vjs-control {
  width: 6em;
}

.video-js .vjs-control-bar {
  background-color: rgba(255, 255, 255, 0);
  width: auto;
  left: 20px;
  right: 20px;
  height: 50px;
  border-radius: 0.5em;
}

.vjs-button > .vjs-icon-placeholder:before {
  font-size: 3em;
  line-height: 1.67;
  width: 100%;
  color: rgb(255, 255, 255, 0.7);
}

.vjs-button .vjs-fullscreen > .vjs-icon-placeholder:before {
  
  color: #ffffff;
}

.vjs-button > .vjs-icon-placeholder:hover {
  text-shadow: 0em 0em 0.7em rgba(255, 255, 255, 1);
  color: white;
}

.vjs-button > .vjs-icon-placeholder:active {
  color: white;
}

.video-js .vjs-play-progress:before {
  color: #ff0000;
  font-size: 1.45em;
}

.video-js .vjs-play-progress {
  background-color: #ff0000;
}

.video-js
  .vjs-progress-control:hover
  .vjs-progress-holder:focus
  .vjs-time-tooltip,
.video-js .vjs-progress-control:hover .vjs-time-tooltip {
  display: none;
}

.vjs-time-tooltip {
  display: none;
}

.video-js .vjs-progress-control {
  position: absolute;
  bottom: 50px;
  width: 100%;
  left: 0px;
  right: 20px;
  z-index: 10;
  height: 8px;
}

.video-js .vjs-fullscreen-control {
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 10;
  width: 50px;
}

.video-js .vjs-subs-caps-button {
  position: absolute;
  bottom: 0px;
  right: 30px;
  z-index: 10;
}

.video-js .vjs-picture-in-picture-control {
  display: none;
}

.vjs-current-time-display {
  font-size: 14px;
}

.video-js .vjs-progress-control .vjs-progress-holder {
  margin: 0px 45px 0px 10px;
  height: 5px;
}

.video-js .icon-angle-left .vjs-icon-placeholder:before {
  color: rgb(255, 255, 255, 0.7);
  font-family: 'Font Awesome 5 Free';
  content: '\f2ea';
  display: block;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}

.video-js .vjs-fullscreen .icon-angle-left .vjs-icon-placeholder:before {
  color: #ffffff;
  
}

.video-js .icon-angle-left .vjs-icon-placeholder:hover {
  text-shadow: 0em 0em 0.7em rgba(255, 255, 255, 1);
  color: #ffffff;
}

.video-js .icon-angle-left {
  cursor: pointer;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.video-js .icon-angle-right .vjs-icon-placeholder:before {
  color: rgb(255, 255, 255, 0.7);
  font-family: 'Font Awesome 5 Free';
  content: '\f2f9';
  display: block;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
}
.video-js .vjs-fullscreen .icon-angle-right .vjs-icon-placeholder:before {
  color: #ffffff;
  
}

.video-js .icon-angle-right .vjs-icon-placeholder:hover {
  text-shadow: 0em 0em 0.7em rgba(255, 255, 255, 1);
  color: #ffffff;
}

.video-js .icon-angle-right,
.video-js .icon-angle-right {
  cursor: pointer;
  -webkit-box-flex: none;
  -moz-box-flex: none;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}

.video-js .vjs-volume-bar {
  top: 10px;
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  border-radius: 5px;
}

.vjs-menu-button-popup .vjs-menu .vjs-menu-content {
  bottom: 4em;
  width: 12em;
  left: -2.6em;
}

.vjs-menu .vjs-menu-content > * {
  padding: 5px;
}

.video-js .vjs-text-track-display > div > div > div {
  font-size: 20px !important;
  line-height: 33px !important;
  padding: 5px !important;
  margin-bottom: 45px;
  background: rgba(0, 0, 0, 0.5) !important;
  text-shadow: 1px 1px 2px #000000;
  display: inline-block !important;
}
