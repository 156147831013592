.thFooterContainer {
  padding: 2% 0px 5% 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.historyTableContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.historyTable {
  width: 100%;
  border-collapse: collapse;
}
.tableHeader {
  border-bottom: 1px solid #bebebe;
  padding: 0px 15px 10px 15px;
  text-align: left;
  color: #5e5d75;
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
}
.tableRow {
  border-bottom: 1px solid #bebebe;
}
.tableColumn {
  padding: 15px;
  color: var(--PrimaryText);
  font-size: 14px;
}
.total {
  color: #645fc2;
}

@media (max-width: 600px) {
  .historyTable {
    width: 95%;
  }
  .tableHeader {
    padding: 0px 0px 10px 0px;
    font-size: 9px;
  }
  .tableColumn {
    padding: 15px 0px 15px 0px;
    color: var(--PrimaryText);
    font-size: 9px;
  }
}
