.alreadySigninText {
  color: var(--PrimaryText);
  cursor: pointer;
  padding-left: 5px;
  font-weight: 600;
}
.signupSubHeader {
  font-size: 14px;
  text-align: center;
  color: white;
  font-weight: 300;
  padding-top: 5px;
}
a {
  text-decoration: none;
}
.alreadyHaveAccount {
  margin-top: 20px;
  color: white;
  font-size: 13px;
}
.padding-top-20 {
  padding-top: 20;
}
.signUpContent {
  display: flex;
  width: 100%;
}
.phoneNumberErrorText {
  color: red;
  font-size: 14px;
  text-align: center;
  /* padding: 0px; */
  margin-bottom: 0px;
}
